.details {
    max-width: 600px;
}

.card-sm {
    max-width: 600px;
}

.pagination {
    display: flex;
    justify-content: end;
}
.page-item.active .page-link {
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.page-link {
    color: #17a2b8;
}

.fa-whatsapp  {
    color:#fff;
    background:
        linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
        radial-gradient(#25d366 60%,transparent 0);
}

.link-badge a:hover {
    text-decoration: none !important;
}



.login {
    background-color: #f8fafc;
}

.required:after {
    content: ' * ';
    color: red;
}

.login-image {
    height: 100vh;
    background-image: url('/img/login.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    box-shadow: 0 0 0.1rem black;    
}

.login-wrap {
    /* margin-top: 10px; */
    /* height: 100vh; */
    padding: 10px;
}

.login-wrap button {
    margin: 2rem 0 2rem 0;
    height: 3rem;
}

.login-wrap .btn-link {
    margin-right: 0px;
    padding: 0px;
}

@media (max-width: 768px)
{
    .login-image {
        height: 25vh;
    }
}

.no-decoration {
    text-decoration: none !important;
}